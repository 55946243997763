<template>
  <div class="home">
    <vue-particles color="47E9FF" class="particles"></vue-particles>
    <!-- Banner -->
    <div class="banner">
      <div class="banner-content">
        <b-row class="align-items-center">
          <b-col
            md="12"
            lg="6"
            data-aos="slide-right"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
          >
            <div class="banner-left">
              <p>AIHA - Set You Free</p>
              <h3>THE BEST AUTOMATED TRADING ROBOT IN THE WORLD</h3>
              <div class="group-btn">
                <a href="https://system.aiha.io/secure/login" target="_blank">
                  <b-button class="btn-signUp">SIGN IN</b-button></a
                >
                <a href="https://system.aiha.io/secure/register" target="_blank"
                  ><b-button class="btn-signIn">SIGN UP</b-button></a
                >
              </div>
            </div>
          </b-col>
          <b-col
            md="12"
            lg="6"
            data-aos="slide-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
          >
            <div class="banner-right">
              <img src="~@/assets/images/img-ytb.png" alt="" />
              <div class="btn-play" v-b-modal.modal-center @click="loadVideo()">
                <img src="~@/assets/images/btn-play.png" alt="" />
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- Sub banner -->
        <div class="sub-banner">
          <b-row class="justify-content-center">
            <b-col
              cols="6"
              md="4"
              data-aos="slide-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="box-item">
                <p>Emotion-Based Trading</p>
              </div>
            </b-col>
            <b-col
              cols="6"
              md="4"
              data-aos="slide-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="box-item">
                <p>Emotion-Based Trading</p>
              </div>
            </b-col>
            <b-col
              cols="6"
              md="4"
              data-aos="slide-left"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="box-item">
                <p>World Economy In Crisis</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <!-- About us -->
    <div class="aboutUs" id="aboutUs">
      <b-container>
        <h3
          data-aos="slide-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
        >
          ABOUT US
        </h3>
        <div class="aboutUs-content">
          <b-row class="align-items-center">
            <b-col
              md="4"
              data-aos="slide-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <img
                src="~@/assets/images/license.png"
                alt=""
                class="img-license"
                @click="$bvModal.show('modal-license')"
              />
            </b-col>
            <b-col
              md="8"
              data-aos="slide-left"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="about-content-company">
                <div class="aboutUs-content-item">
                  <h4>Company name:</h4>
                  <p>EggsBook Unichain Solutions LLC</p>
                </div>
                <div class="aboutUs-content-item">
                  <h4>Established Time:</h4>
                  <p>9th November, 2021</p>
                </div>
                <div class="aboutUs-content-item">
                  <h4>Adress:</h4>
                  <p>
                    Suite 305, Griffith Corporate Centre, Kingstown, St. Vincent and the Grenadines
                  </p>
                </div>
                <div class="aboutUs-content-item">
                  <h4>Company Code Numer:</h4>
                  <p>1569 LLC 2021</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Advantage -->
    <div class="Advantage" id="advantage">
      <b-container>
        <h3
          data-aos="flip-left"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
        >
          Advantage
        </h3>
        <b-row class="justify-content-center">
          <b-col cols="12" sm="6" lg="4" class="mb-5">
            <div
              class="box-item"
              data-aos="flip-left"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <p>Various Support Instruments</p>
              <p>
                Use advanced technical indicators including RSI, EMA, Bollinger Band, Stoch,
                StochRSI, Ichimoku, ADX, MFI, ...
              </p>
            </div>
          </b-col>
          <b-col cols="12" sm="6" lg="4" class="mb-5">
            <div
              class="box-item"
              data-aos="flip-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <p>Various Trading Strategies</p>
              <p>
                Provide a variety of strategies to support traders such as Trailing Stop, DCA,
                Reversal Trading...
              </p>
            </div>
          </b-col>
          <b-col cols="12" sm="6" lg="4" class="mb-5">
            <div
              class="box-item"
              data-aos="flip-left"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <p>Absolute safety</p>
              <p>
                Commitment to not cause any problems with the account as well as the trader's
                information
              </p>
            </div>
          </b-col>
          <b-col cols="12" sm="6" lg="4" class="mb-5">
            <div
              class="box-item"
              data-aos="flip-left"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <p>Continuous Automated Trading</p>
              <p>
                Save time and improve your ability to trade profitably as the software will analyze
                and trade continuously
              </p>
            </div>
          </b-col>
          <b-col cols="12" sm="6" lg="4" class="mb-5">
            <div
              class="box-item"
              data-aos="flip-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <p>Easy Using Interface</p>
              <p>
                Easy Using Interface The interface is suitable for both professional traders and
                newbies
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- Team -->
    <div class="team" id="team" v-if="false">
      <b-container>
        <h3
          data-aos="flip-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
        >
          Team
        </h3>
        <b-row class="justify-content-center">
          <b-col cols="6" sm="6" md="4" lg="3" class="mb-5">
            <div
              class="team-content"
              data-aos="flip-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="team-img">
                <img src="~@/assets/images/avatar.png" alt="" />
              </div>
              <div class="team-main">
                <div class="box-item">
                  <p>Daniel Paul</p>
                  <p>CEO</p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6" sm="6" md="4" lg="3" class="mb-5">
            <div
              class="team-content"
              data-aos="flip-down"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="team-img">
                <img src="~@/assets/images/avatar.png" alt="" />
              </div>
              <div class="team-main">
                <div class="box-item">
                  <p>Lorenz Arjay</p>
                  <p>Software Developer</p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6" sm="6" md="4" lg="3" class="mb-5">
            <div
              class="team-content"
              data-aos="flip-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="team-img">
                <img src="~@/assets/images/avatar.png" alt="" />
              </div>
              <div class="team-main">
                <div class="box-item">
                  <p>Alex Kurokhin</p>
                  <p>Software Developer</p>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6" sm="6" md="4" lg="3" class="mb-5">
            <div
              class="team-content"
              data-aos="flip-down"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <div class="team-img">
                <img src="~@/assets/images/avatar.png" alt="" />
              </div>
              <div class="team-main">
                <div class="box-item">
                  <p>Vincent Le Bartz</p>
                  <p>Project Manager</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="bg-blog-contact" v-if="false">
      <!-- knowledge blog -->
      <div class="knowledge-blog" id="blog">
        <b-container>
          <h3
            data-aos="slide-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
          >
            KNOWLEDGE BLOG
          </h3>
          <b-row class="justify-content-center">
            <b-col cols="12" sm="6" md="6" lg="4" class="mb-5">
              <div
                class="blog-iem"
                data-aos="slide-down"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
              >
                <img src="~@/assets/images/img-blog.png" alt="" />
                <div class="blog-item-content">
                  <h3>WHAT IS TRADING BOT?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae fermentum
                    congue enim dolor sed dictum quam…
                  </p>
                  <b-button>Read more</b-button>
                </div>
              </div>
            </b-col>
            <b-col cols="12" sm="6" md="6" lg="4" class="mb-5">
              <div
                class="blog-iem"
                data-aos="slide-up"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
              >
                <img src="~@/assets/images/img-blog.png" alt="" />
                <div class="blog-item-content">
                  <h3>THE ADVANTAGES OF TRADING BOT</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. enim dolor sed dictum
                    quam…
                  </p>
                  <b-button>Read more</b-button>
                </div>
              </div>
            </b-col>
            <b-col cols="12" sm="6" md="6" lg="4" class="mb-5">
              <div
                class="blog-iem"
                data-aos="slide-down"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
              >
                <img src="~@/assets/images/img-blog.png" alt="" />
                <div class="blog-item-content">
                  <h3>"TIPS" TO USE TRADING BOT EFFECTIVELY</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim dolor dictum
                    quam…
                  </p>
                  <b-button>Read more</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- Contact -->
      <div class="contact" id="contact">
        <b-container>
          <h3
            data-aos="slide-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
          >
            CONTACT
          </h3>
          <div
            class="contact-content"
            data-aos="slide-up"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
          >
            <b-row class="align-items-center">
              <b-col cols="12" lg="6">
                <b-form class="form-support">
                  <p>Full name</p>
                  <b-input type="text"></b-input>
                  <p>Email</p>
                  <b-input type="text"></b-input>
                  <p>Question Or Message</p>
                  <b-form-textarea id="textarea" rows="6" max-rows="6"></b-form-textarea>
                  <div class="btn-send">
                    <b-button>Send</b-button>
                  </div>
                </b-form>
              </b-col>
              <b-col cols="12" lg="6">
                <div class="support-content">
                  <h4>
                    SUPPORT <br />
                    HAPPY TO HELP
                  </h4>
                  <p>If you have any questions, do not hesitate to send them to us</p>
                  <p>
                    The questions will be answered by our team in 24 <br />
                    hours
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </div>
    <!-- Logo -->
    <div class="logo" id="pricing">
      <img
        src="~@/assets/images/logo-big.png"
        alt=""
        data-aos="slide-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
      />
      <b-container>
        <b-row class="align-items-center">
          <b-col cols="12">
            <div
              class="box-item"
              data-aos="slide-right"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <p>
                AIHA is a non-custodial trading platform or monitored by any third party. We
                developed this platform with the desire to provide automated trading solutions that
                are safe, efficient and minimize risk for traders thanks to advanced Blockchain
                technologies.
              </p>
            </div>
            <div
              class="box-item"
              data-aos="slide-left"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
            >
              <h3>Advice</h3>
              <p>
                AIHA is an automated trading support tool, it helps traders make buying or selling
                decisions based on analysis and signals from the market and not based on emotions at
                all. Besides, this tool will support effective transaction negotiation and secure
                transaction. <br />
                The advice is to become a smart trader, using AIHA robot as an investment management
                tool with the same level of efficiency as financial experts with the formula
                "Health, Intelligence and Discipline“
              </p>
              <p>INDEPENDENT COMES FROM AIHA - FINANCIAL FREEDOM</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- Modal video -->
    <b-modal id="modal-activate" centered title="BootstrapVue" size="xl" hide-footer hide-header>
      <div class="box-item">
        <h4>$100/Month</h4>
        <p>
          Various Trading Bots <br />
          Backtesting <br />
          Allowed to use multiple trading instruments at the same time <br />
          Continuous transaction status notification <br />
          24/7 support <br />
          Passive commission <br />
          Join programs and events
        </p>
        <b-button>Activate</b-button>
        <div class="box-note">
          <h5>Note:</h5>
          <p>Login your account HERE to activate Bot Trading</p>
        </div>
      </div>
    </b-modal>
    <!-- Modal license -->
    <b-modal id="modal-license" centered title="BootstrapVue" hide-footer hide-header>
      <div class="license-content">
        <img src="~@/assets/images/giay-phep.jpg" alt="" />
        <div class="close-license" @click="$bvModal.hide('modal-license')">x</div>
      </div></b-modal
    >
    <!-- Modal-activate -->
    <b-modal id="modal-center" centered title="BootstrapVue" size="xl" hide-footer hide-header>
      <div class="youtube-section">
        <div id="player" />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      player: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    loadVideo() {
      function onPlayerReady(event) {
        event.target.playVideo();
      }
      // eslint-disable-next-line no-unused-vars
      let player = null;

      function setupPlayer() {
        window.YT.ready(() => {
          player = new window.YT.Player('player', {
            width: '100%',
            height: '100%',
            videoId: 'uwMjtGqR9r0',
            events: {
              onReady: onPlayerReady,
            },
            playerVars: {
              playsinline: 1,
              controls: 0,
              disablekb: 1,
              fs: 0,
              iv_load_policy: 3,
              rel: 0,
              showinfo: 1,
              wmode: 'opaque',
              origin: 'http://localhost:8080',
            },
          });
        });
      }

      (function loadYoutubeIFrameApiScript() {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        tag.onload = setupPlayer;
      }());
    },
  },
};
</script>

<style lang="scss">
#modal-license {
  .modal-dialog {
    max-width: 600px;
    width: 100%;
    .modal-content {
      .modal-body {
        padding: 0px;
        .license-content {
          position: relative;
          img {
            width: 100%;
            height: auto;
          }
          .close-license {
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            background-color: #000000;
            font-weight: bold;
            line-height: 120%;
          }
        }
      }
    }
  }
}
.home {
  .banner {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 100px);
    background-image: url('~@/assets/images/bg-baner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      min-height: calc(100vh - 80px);
    }
    .banner-content {
      width: 100%;
      max-width: 1500px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 15px;
      position: relative;
      z-index: 10;
      @media (min-width: 576px) {
        max-width: 540px;
      }
      @media (min-width: 768px) {
        max-width: 720px;
      }
      @media (min-width: 992px) {
        max-width: 960px;
      }
      @media (min-width: 1200px) {
        max-width: 1140px;
        padding: 100px 15px;
      }
      @media (min-width: 1441px) {
        max-width: 1300px;
      }
      @media (min-width: 1600px) {
        max-width: 1400px;
      }
      .banner-left {
        color: #ffffff;
        @media (max-width: 991px) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin-bottom: 30px;
        }
        p {
          opacity: 0.8;
          font-size: 18px;
          margin: 0px 0px 24px 0px;
          @media (max-width: 1200px) {
            font-size: 16px;
          }
          @media (max-width: 575px) {
            margin: 0px 0px 15px 0px;
          }
        }
        h3 {
          font-weight: bold;
          font-size: 32px;
          margin: 0px 0px 24px 0px;
          line-height: 42px;
          @media (max-width: 1200px) {
            font-size: 26px;
          }
          @media (max-width: 575px) {
            font-size: 22px;
            line-height: 35px;
            margin: 0px 0px 15px 0px;
          }
        }
        .group-btn {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 130px;
            height: 40px;
            border: none;
            border-radius: 20px;
            font-weight: bold;
            &.btn-signUp {
              background: linear-gradient(135deg, #ff3333 0%, #541012 100%);
              box-shadow: inset -4px -4px 8px rgba(0, 0, 0, 0.25),
                inset 4px 4px 8px rgba(255, 255, 255, 0.25);
              margin-right: 30px;
            }
            &.btn-signIn {
              background: linear-gradient(135deg, #2892ce 0%, #093a55 100%);
              box-shadow: inset -4px -4px 8px rgba(0, 0, 0, 0.25),
                inset 4px 4px 8px rgba(255, 255, 255, 0.25);
            }
          }
        }
      }
      .banner-right {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          box-shadow: 0px 0px 20px #18acfe;
          border-radius: 15px;
        }
        .btn-play {
          position: absolute;
          cursor: pointer;
          background-color: #000000;
          width: 100px;
          height: 60px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 575px) {
            width: 65px;
            height: 40px;
          }
          img {
            width: auto;
            height: 80%;
            box-shadow: none;
          }
        }
      }
      .sub-banner {
        width: 100%;
        height: auto;
        padding: 0px 0px;
        margin-top: 50px;
        @media (min-width: 576px) {
          max-width: 540px;
        }
        @media (min-width: 768px) {
          max-width: 720px;
        }
        @media (min-width: 992px) {
          max-width: 960px;
          padding: 0px 15px;
        }
        @media (min-width: 1200px) {
          max-width: 1140px;
        }
        @media (min-width: 1441px) {
          max-width: 1300px;
        }
        @media (min-width: 1600px) {
          max-width: 1000px;
        }
        .box-item {
          width: 100%;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 50px;
          text-align: center;
          background: linear-gradient(
            135deg,
            rgba(7, 63, 95, 0.28) 0%,
            rgba(7, 67, 101, 0.46) 100%
          );
          box-shadow: 0px 0px 20px #18acfe;
          border-radius: 10px;
          color: #fff;
          @media (max-width: 991px) {
            padding: 0px 20px;
            height: 100px;
            margin-bottom: 30px;
          }
          @media (max-width: 375px) {
            padding: 0px 10px;
            height: 80px;
          }
          p {
            font-weight: bold;
            font-size: 20px;
            line-height: 36px;
            margin-bottom: 0px;
            @media (max-width: 991px) {
              font-size: 16px;
              line-height: 32px;
            }
            @media (max-width: 375px) {
              font-size: 14px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
  .aboutUs {
    width: 100%;
    height: 100%;
    color: #ffffff;
    padding: 100px 0px 50px 0px;
    box-shadow: -1px -1px 20px #000000;
    background-color: #000000;
    background-image: url('~@/assets/images/bg-aboutUs.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 767px) {
      padding: 80px 0px 20px 0px;
    }
    @media (max-width: 575px) {
      padding: 20px 0px;
    }
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
      position: relative;
      z-index: 10;
    }
    .aboutUs-content {
      padding: 70px;
      background: linear-gradient(135deg, rgba(7, 63, 95, 0.28) 0%, rgba(7, 67, 101, 0.46) 100%);
      border-radius: 54px;
      border: 1px solid #2892ce;
      position: relative;
      z-index: 10;
      @media (max-width: 575px) {
        padding: 20px;
        border-radius: 20px;
      }
      .img-license {
        width: 100%;
        height: auto;
        cursor: pointer;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        @media (max-width: 575px) {
          border-radius: 20px;
        }
      }
      .about-content-company {
        .aboutUs-content-item {
          display: flex;
          align-items: baseline;
          margin-bottom: 20px;
          @media (max-width: 991px) {
            flex-wrap: wrap;
            margin-bottom: 15px;
          }
          @media (max-width: 767px) {
            justify-content: center;
          }
          h4 {
            margin-bottom: 0px;
            width: 100%;
            max-width: 230px;
            @media (max-width: 1199px) {
              max-width: 230px;
              font-size: 18px;
            }
            @media (max-width: 991px) {
              max-width: inherit;
              width: fit-content;
              margin-right: 10px;
            }
          }
          p {
            font-size: 20px;
            margin-bottom: 0px;
            @media (max-width: 1199px) {
              font-size: 16px;
            }
            @media (max-width: 767px) {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .Advantage {
    width: 100%;
    height: 100%;
    color: #ffffff;
    padding: 100px 0px 0px 0px;
    box-shadow: -1px -1px 20px #000000;
    background-color: #000000;
    background-image: url('~@/assets/images/bg-advantage.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 767px) {
      padding: 80px 0px;
    }
    @media (max-width: 575px) {
      padding: 20px 0px;
    }
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
      position: relative;
      z-index: 10;
    }
    .box-item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      background: linear-gradient(135deg, rgba(7, 63, 95, 0.28) 0%, rgba(7, 67, 101, 0.46) 100%);
      box-shadow: 0px 0px 20px #18acfe;
      border-radius: 10px;
      color: #fff;
      padding: 40px 10px;
      position: relative;
      z-index: 10;
      @media (max-width: 991px) {
        padding: 0px 20px;
        margin-bottom: 30px;
      }
      @media (max-width: 375px) {
        padding: 0px 10px;
      }
      p {
        font-weight: bold;
        font-size: 22px;
        line-height: 36px;
        margin-bottom: 0px;
        @media (max-width: 1200px) {
          font-size: 18px;
        }
        @media (max-width: 991px) {
          font-size: 16px;
          line-height: 32px;
        }
        @media (max-width: 375px) {
          font-size: 14px;
          line-height: 26px;
        }
        &:last-child {
          font-weight: normal;
          font-size: 18px;
          margin-top: 35px;
          @media (max-width: 1200px) {
            font-size: 16px;
            margin-top: 30px;
          }
        }
      }
    }
  }
  .team {
    width: 100%;
    height: 100%;
    color: #ffffff;
    padding: 100px 0px 0px 0px;
    box-shadow: -1px -1px 20px #000000;
    background-color: #000000;
    background-image: url('~@/assets/images/bg-team.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 767px) {
      padding: 80px 0px;
    }
    @media (max-width: 575px) {
      padding: 20px 0px;
    }
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 50px;
      position: relative;
      z-index: 10;
    }
    .team-content {
      text-align: center;
      position: relative;
      z-index: 10;
      .team-img {
        img {
          width: 250px;
          height: auto;
          @media (max-width: 1200px) {
            width: 200px;
          }
          @media (max-width: 480px) {
            width: 100%;
          }
        }
      }
      .team-main {
        .box-item {
          margin-top: 30px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          background: linear-gradient(
            135deg,
            rgba(7, 63, 95, 0.28) 0%,
            rgba(7, 67, 101, 0.46) 100%
          );
          border-radius: 10px;
          color: #fff;
          padding: 20px 10px;
          border: 1px solid #2892ce;
          @media (max-width: 480px) {
            padding: 10px 5px;
          }
          p {
            font-weight: bold;
            font-size: 22px;
            line-height: 36px;
            margin-bottom: 0px;
            @media (max-width: 1200px) {
              font-size: 18px;
            }
            @media (max-width: 991px) {
              font-size: 16px;
              line-height: 32px;
            }
            @media (max-width: 480px) {
              font-size: 14px;
              margin-bottom: 0px;
            }
            @media (max-width: 375px) {
              font-size: 14px;
              line-height: 26px;
            }
            &:first-child {
              font-weight: normal;
              font-size: 18px;
              margin-bottom: 10px;
              @media (max-width: 1200px) {
                font-size: 16px;
              }
              @media (max-width: 480px) {
                font-size: 12px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
  .bg-blog-contact {
    background-color: #000000;
    background-image: url('~@/assets/images/bg-blog.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  .knowledge-blog {
    width: 100%;
    height: 100%;
    color: #ffffff;
    padding: 100px 0px 0px 0px;
    @media (max-width: 767px) {
      padding: 80px 0px;
    }
    @media (max-width: 575px) {
      padding: 20px 0px;
    }
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 50px;
      position: relative;
      z-index: 10;
    }
    .blog-iem {
      background: linear-gradient(135deg, rgba(7, 63, 95, 0.28) 0%, rgba(7, 67, 101, 0.46) 100%);
      box-shadow: 0px 0px 20px #18acfe;
      backdrop-filter: blur(20px);
      border-radius: 10px;
      height: 100%;
      position: relative;
      z-index: 10;
      img {
        width: 100%;
        height: auto;
      }
      .blog-item-content {
        padding: 30px 15px;
        h3 {
          text-align: left;
          margin-bottom: 20px;
          @media (max-width: 1200px) {
            font-size: 18px;
          }
        }
        p {
          color: #ffffff;
          opacity: 0.8;
          font-size: 18px;
          margin-bottom: 20px;
          @media (max-width: 1200px) {
            font-size: 14px;
          }
        }
        button {
          background: linear-gradient(135deg, #ff3333 0%, #541012 100%);
          border-radius: 10px;
          border: 0;
        }
      }
    }
  }
  .contact {
    width: 100%;
    height: 100%;
    color: #ffffff;
    padding: 100px 0px 0px 0px;
    @media (max-width: 767px) {
      padding: 80px 0px;
    }
    @media (max-width: 575px) {
      padding: 20px 0px;
    }
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 50px;
      position: relative;
      z-index: 10;
    }
    .contact-content {
      padding: 70px;
      background: linear-gradient(135deg, rgba(7, 63, 95, 0.28) 0%, rgba(7, 67, 101, 0.46) 100%);
      border-radius: 54px;
      border: 1px solid #2892ce;
      position: relative;
      z-index: 10;
      @media (max-width: 575px) {
        padding: 20px;
        border-radius: 20px;
      }
      .form-support {
        p {
          margin-bottom: 10px;
        }
        input {
          margin-bottom: 30px;
        }
        textarea {
          margin-bottom: 30px;
        }
        .btn-send {
          @media (max-width: 991px) {
            width: 100%;
            text-align: center;
          }
          button {
            background: linear-gradient(135deg, #ff3333 0%, #541012 100%);
            border-radius: 10px;
            border: 0;
            width: 100px;
          }
        }
      }
      .support-content {
        width: 100%;
        text-align: center;
        margin-top: -50px;
        @media (max-width: 991px) {
          margin-top: 30px;
        }
        h4 {
          line-height: 40px;
          margin-bottom: 35px;
          @media (max-width: 575px) {
            margin-bottom: 15px;
            line-height: 30px;
          }
        }
        P {
          &:nth-child(2) {
          }
          &:nth-child(3) {
            font-weight: 100;
            font-style: italic;
          }
        }
      }
    }
  }
  .logo {
    width: 100%;
    height: 100%;
    color: #ffffff;
    padding: 100px 0px 0px 0px;
    box-shadow: -1px -1px 20px #000000;
    text-align: center;
    background-color: #000000;
    background-image: url('~@/assets/images/bg-aiha.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 767px) {
      padding: 80px 0px;
    }
    @media (max-width: 575px) {
      padding: 20px 0px;
    }
    img {
      width: 600px;
      height: auto;
      position: relative;
      z-index: 10;
      @media (max-width: 767px) {
        width: 450px;
      }
      @media (max-width: 575px) {
        width: 350px;
      }
      @media (max-width: 375px) {
        width: 320px;
      }
    }
    .box-item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px 100px;
      text-align: center;
      background: linear-gradient(135deg, rgba(7, 63, 95, 0.28) 0%, rgba(7, 67, 101, 0.46) 100%);
      border: 1px solid #093a55;
      border-radius: 10px;
      color: #fff;
      margin: 50px 0px;
      position: relative;
      z-index: 10;
      @media (max-width: 991px) {
        padding: 50px 50px;
      }
      @media (max-width: 767px) {
        padding: 30px;
      }
      @media (max-width: 480px) {
        padding: 15px;
      }
      h3 {
        font-weight: bold;
        margin-bottom: 15px;
        @media (max-width: 480px) {
          font-size: 20px;
        }
      }
      p {
        margin-bottom: 0px;
        @media (max-width: 480px) {
          font-size: 14px;
        }
        &:nth-child(2) {
          margin-bottom: 15px;
        }
        &:nth-child(3) {
          color: #ff3333;
          font-weight: bold;
        }
      }
    }
  }
}
#modal-center {
  .modal-body {
    height: 500px;
    padding: 0px;
    .youtube-section {
      height: 100%;
    }
  }
}
</style>
